
.header-container{
  background: rgba(0, 0, 0, 0.5);
  /* overflow: hidden; */
  /* background: linear-gradient(75deg, rgba(241, 114, 41,0.6), rgba(107, 53, 3,0.6), rgba(194, 69, 27, 0.6), rgba(129, 32, 0,0.5),rgb(46, 11, 0,0.5), rgba(19, 5, 0,0.6),rgb(0, 0, 0,0.6), rgba(0, 0, 0,0.6), rgba(53, 19, 0,0.5), rgba(114, 46, 0,0.5), rgba(151, 81, 0,0.5), rgba(168, 90, 0,0.5),  rgba(177, 71, 0,0.5), rgba(121, 35, 4, 0.6)); */
z-index: 9;
/* padding-top: 20px; */
  position: fixed;
  width: 100%;
}
.navdropdown {
  list-style: none;
}

.navitemlist{
  top: 15px;
padding-top: 10px;
  /* background-color: blue; */
  padding-right: 10px;
  bottom: 0;
  position: relative;
}

.header-icon{
  border-bottom: 1px solid rgba(255, 255, 255, 0.555) !important;
  width: calc(100% - 285px);
  height: 49px;
  padding: 10px;
  /* display: none; */
  right: 0;
  top: -20px;  
  box-shadow: 0 0px .1px rgb(57, 53, 53);
  position:absolute;
  /* background-color: rgba(255, 0, 0, 0.651); */
}
.header-icon p{
  color: white !important;
  margin: 0 10px;
  text-shadow: 0 2px 3px rgb(46, 46, 46);
}
.header-icon a i{
  color: white !important;
  font-size: 20px;
  top: -10px;
  margin: 5px;
  text-shadow: 0 2px 3px rgb(46, 46, 46);
  position: relative;
}
 /* #headercon{ background-color: rgb(255, 196, 0) !important;} */
@media only screen and (max-width: 991px) {
  /* #headercon{ background-color: rgb(255, 251, 0) !important;} */
}
.navcontainer{
  padding-top: 20px;
  /* background-color: rgb(0, 255, 55) !important; */
  width: 100%;
}
.navbar{
  background-color: rgba(0, 255, 255, 0) !important;
}

.navbar-brand img{
height: 60px;
}
.nav-item{
  /* background-color: aquamarine; */
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  bottom: 13px;
  position: relative;
}
.nav-item a{
  color: white !important;
  /* background-color: #0059ff94; */

  text-shadow: 0 2px 3px rgb(46, 46, 46);
}

.cusnavbtn{
  background-color: rgb(241, 91, 41);
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  display: none;
  margin-left: 20px;
  border-radius: 0 ;
  margin-bottom: 10px;
  border-radius: 15px;
  margin-top: 0px;
  box-shadow: 0 2px 3px rgb(46, 46, 46);
  text-shadow: 0 2px 3px rgb(46, 46, 46);
}
.nav-item button{
  background-color: rgb(241, 91, 41);
  color: white !important;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0 ;
  margin-bottom: 10px;
  margin-top: 0px;
  box-shadow: 0 2px 3px rgb(46, 46, 46);
  text-shadow: 0 2px 3px rgb(46, 46, 46);
}

.custoggalbtn{display: none;}

@media only screen and (min-width: 991px) {
.container-fluid .tggler-box{
  /* background-color: rgba(0, 255, 255, 0.5); */
  left: 285px;
  height: 58px;
  top: 28px;
  border-left: 1px solid rgba(255, 255, 255, 0.452) !important;
  position: absolute;
}.header-container{
  border-bottom: 1px solid rgba(255, 255, 255, 0.445);}
  .header-icon{
    border-left: 1px solid rgba(255, 255, 255, 0.534);}
}

@media (min-width:991px) and (max-width:1048px) {
.nav-item{margin-left: 4px;
}}
@media (min-width:991px) and (max-width:1284px) {
.nav-item button{
display: none;
}
.cusnavbtn{
  display: block;

}

}
@media only screen and (max-width: 991px) {


  .nav-item button{
    display: none;
  }


.responsive-top{
  display: block !important;
}

.container-fluid{
  margin-top: 10px;
}

.header-icon{
  position: absolute !important;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-left: 0 !important;
}
.header-icon p{display: none;}
.header-icon a i{
  margin-left: 20px;
  font-size: 15px;
}


  .custoggalbtn{
    display: block !important;
    background-color: rgba(0, 0, 255, 0);
    border: none;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    position: fixed;
    right: 40px;
    top: 40px;
    color: white !important;
  }
  .custoggalbtn i{
    font-size: 40px;
    text-shadow: 0 2px 3px rgb(46, 46, 46);
    color: white !important;
    font-weight: bolder !important;}

  .tggler-box{
    background-color: rgba(0, 0, 0, 0.74);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 1;
    top: 0;
  }
  .bshow{
    display: block;
  }


  .container-fluid .hide{
  /* background-color: rgb(255, 0, 0); */
  /* display: block; */
  top: 0;
  position: fixed;
  right: -150px;
  height: 100%;
opacity: 0.3;
animation: myfirst2 0.5s ;
animation-direction: alternate;
}
@keyframes myfirst2 {
  0%   {right: 0px;}
 
  100% {right: -150px;}}


.container-fluid .show{
  top: 0;
  background: linear-gradient(35deg, rgb(241, 91, 41), rgb(255, 136, 0), rgb(241, 91, 41),  rgb(241, 91, 41), rgb(121, 35, 4)) !important;
 position: fixed;
 padding: 20px 100px 0 20px;
 right: 0;
 z-index: 999999999999999999!important;
 height: 100%;
 animation: myfirst 0.5s ;
 animation-direction: alternate;
}
@keyframes myfirst {
  0%   {right: -150px;
  opacity: 0.5;}
 
  100% {right: 0;
  opacity: 1;}}
}





.item{
  background-color: aqua;
  height: 100px;
  width: 100px;
  margin: 20px;
}


/* banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner********banner****************/

.bannercontainer{
  position: relative;
  /* top: -120px; */
  padding: 0px;

}

.carousel-item-box{
  height: 100vh;
}
.carousel-item-box h1{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-size: 55px;
font-weight: 700;
color: white;
text-shadow: 0 2px 3px rgb(46, 46, 46);
}




.bi{font-size: 40px;}

.ishmbannerimg{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .ishmbannervideoactive{
  background-color: aqua;
} */


.ishmbannervideo{
  position: relative;
  top: -130px;
  height: calc(100% + 260px) !important;
  width: 100%;
  /* opacity: 0.5 !important; */
  object-fit: cover;
}

/* study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********study-sectoin***********/
/* .study-container{
  background: red;
} */

@media only screen and (max-width: 991px) {
.study-container{
  margin-bottom: 50px;
}

.studycard{
margin-top: 20px !important;}
}
@media only screen and (max-width: 767px) {
  .studycard{
    margin: 20px;
  }
  #studyslidersm{
    display: block !important;
  }
  #studysliderlg{
    display: none;
  }


}

#studysliderlg{
  display: none;
}

/* #studyslidersm{
  display: none;
} */



.studycard{
  /* background-color: bisque; */
  /* margin-top: 100px; */
  margin-top: 20px;
  border: none;
  overflow: hidden;
  padding-right: 40px;
  border-radius: 0;}

  .studycard h1{
    margin-bottom: 60px;
    font-size: 50px;
  }
  .studycard p{
    font-size: 20px;
    text-align: justify;
  }
  .studycard button{
    font-size: 19px;
    border: none;
    font-weight: 400;
    border-radius: 5px;
    color: white;
    height: 60px;
    margin-top: 50px;
    width: 300px;
    background-color: rgb(236,197,59);
  }





  /* @media only screen and (max-width: 767px) {
    .score{ */
.score{
position: relative;
top: -100px;
margin-top: 0px;
left: 0px;
z-index: 1;
padding: 10px;

}


.scorecontainer{
  position: relative;
  background-color: rgb(27, 25, 24);
  padding: 20px;
  border-radius: 10px ;
}

.scorecontain{
  position: relative;
  top: 0;
  min-height: 150px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}


.scorecontainbox{
  position: absolute;
  
}
.scorebox{
  text-align: center;
  padding: 10px 10px 1px 10px;
}

.scorebox h2{
  font-size: 35px;
  font-weight: bolder;
  color: #ffffff;
}
.scorebox p{
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;}











.carosel-card{
  /* background-color: rgba(127, 255, 212, 0.432); */
  padding-bottom: 80px;
  margin-bottom: 20px;
  border: none;
}
/* .study-body{
  background-color: rgba(26, 39, 228, 0.432);

} */
.study-body h3{
  color: grey;
  margin-top: 15px;

}

  .carrow i{
    background-color: rgb(255, 208, 0);
    position: absolute;
    bottom: -60px;
    height: 10px;
    width: 58px;
    color: white;
    border-radius: 5px;
    font-size: 38px !important;
    padding-top: 0px !important;
    padding-bottom: 60px;
  }

  .carousel-control-next, .carousel-control-prev{
    opacity: 0.9;
  }
  .study-next{
    position: absolute;
    left: 80px;
  } 





  /* Courses*********Courses*********Courses*********Courses*********Courses*********Courses*********Courses*********Courses*********Courses*********Courses*********Courses*********/

  .coursesparentdiv{
    padding: 80px 0 30px 0;
    margin: 0;
    background-color: rgb(241, 91, 41) !important;
   
  }

  .corscontainer{
    margin: 0;
    padding: 0px 10px;
    margin-top: 30px;
  }

  


  /* .courses-title h2{
    ma
  } */
  .courcebox div{
    position: relative;
    margin-top: 20px;
    cursor: pointer;
  }
  .courcebox div p{
    margin: 20px 12px 20px 0;;
    position: absolute;
    /* bottom:  10px; */bottom:  50px;
    color: white;
    padding: 5px 10px;
    transition: 0.5s ;
    /* background-color: rgb(0, 195, 255); */
    z-index: 5555;
    font-weight: bolder;
    text-shadow: 0 2px 3px rgb(46, 46, 46);
  }
  .courcebox div:hover p{
    /* bottom:  50px; */
    transition: 0.5s ;
    /* background-color: blue !important; */
  }
  
  .courcebox div button{
    /* display: none; */
    margin: 20px 12px 20px 15px;;
    position: absolute;
    bottom:  10px;
    left: 10px;
    /* color: white; */
    padding: 5px 10px;
    background-color: #fd8700; /* background: linear-gradient(45deg, rgba(0, 0, 0, 0.74),  rgba(80, 43, 0, 0.637),  rgba(150, 60, 0, 0.719), rgba(255, 68, 0, 0.747)); */
    border-radius: 3px;
    z-index: 5555;
    font-weight: bolder;
    border: none;
    z-index: 5;
    color: #ffffff;
    transition: 0.3s ;
    box-shadow: 0 2px 3px rgb(46, 46, 46);
    /* text-shadow: 0 2px 3px rgb(46, 46, 46); */
  }
  .courcebox div button:hover{
    color: black !important;
    background-color: #fd8700 !important;
    transition: 0.3s ;
    
    /* text-shadow: 0 2px 3px rgb(214, 214, 214); */
  
    /* background: linear-gradient(225deg, rgba(58, 22, 1, 0.8),  rgba(80, 43, 0, 0.8),  rgba(150, 60, 0, 0.9), rgba(255, 68, 0, 0.9)); */
  }
  .courcebox div button:active{   color: white !important;
    background-color: #000 !important;
  }

  .courcebox div:hover button{
    transition: 0.9s ;
    display: block;
    color: white;
    background-color: #000;
  }

  /* .courceimgbox{
    position: relative;
    height: 100%;
    width: 100%;
  } */

  /* .courcebox div:hover{
    background: linear-gradient(180deg, black, black,white);
    padding: 0;

  } */
  .courcebox div span{
    height: calc(100% - 20px);;
    width: calc(100% - 20px);
    position: absolute;
    top: calc(50% + 10px);;  
    left: 50%; 
    opacity: 0;
    transition: 0.5s ;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, black, black,white);
  }


 
  .courcebox div:hover .boxopa{
    opacity: 0.5;
    transition: 0.5s ;
  }



  .courcebox{
    padding: 10px ;
    margin: 0;
    position: relative;
    width: 100%;
  }

  /* .courseimgbox{
    background-color: #c7b030;
    padding: 10px;
    position: relative;
    top: 0;
    left: 0;
  } */
  .courceimg{

    object-fit: cover;
    width: 100%;
    height: 100%;
    
  }

  .arrocon{
    margin-top: 20px;
  }
  

  /* testimonials************testimonials************testimonials************testimonials************testimonials************testimonials************testimonials************testimonials************testimonials************testimonials************testimonials************/
  .testimonialboxcon{
    /* min-height: 0vh; */
    background-color: rgb(255, 208, 0);
    padding: 80px 20px 100px 20px;
  }
  
  
  
  /* .testimonials-container{

    background-color: aquamarine;
  } */

  .testimonials-container h4{
    /* background-color: rgb(185, 255, 127); */
    text-align: center;
    margin-top: 0px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    font-size: 25px;
    text-shadow: 0 2px 3px rgb(56, 56, 56);
  }
  .testimonials-container h1{
    /* background-color: rgb(185, 255, 127); */
    text-align: center;
    margin-top: 30px;
    text-shadow: 0 2px 3px rgb(56, 56, 56);
    color: rgb(255, 255, 255);
    font-size: 45px;
  }


  .testobox{
    /* background-color: aqua; */
    padding: 0 !important;
  }
  .testobox div .card{
    padding: 10px;
    border: none;
    border-radius: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: calc(35% + 10px);

    /* background-color: rgb(255, 0, 0); */
  }
  .testobox div .card h5{
    /* background-color: aquamarine; */
    font-size: 30px;
    margin-left: 10px;
  }
  .testobox div .card p{
   /* background-color: yellow; */
   margin-left: 10px;
   font-size: 20px;
  }


.testo-img{
  height: 90%;
  width: 35%;
  position: absolute;
  top: 50%;  
  transform: translate(-100%, -50%);
}
.testo-img img{
  object-fit: cover;
  height: 100% !important;
  width: 100%;
}

.tistorow{
  padding-top: 80px;
}

.nevi-butn{
  position: absolute;
  top: calc(0% - 80px); 
  left: 50%; 
  transform: translate(-50%, 0%);
  height: 100px !important;
  width: 100px !important;
}

.arros{
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding-bottom: 20px;
  cursor: pointer;
}
.arros:hover{
  background-color: #ffdeb4;
 outline: rgba(37, 31, 0, 0.774) 2px solid;
}
.arros i{
  position: relative;
  top: -10px;
  color: black;
}
  .newsparentdiv {
    /* min-height: 100vh; */
    padding-top: 80px;
    background-color: rgb(35, 32, 31);
  }

  #testoslidersm{
  display: none;}

  @media only screen and (max-width: 767px) {

    #testosliderlg{
    display: none;}
    #testoslidersm{
    display: block !important;}
  }


/* footre************footre************footre************footre************footre************footre************footre************footre************footre************footre************footre************footre************/


  .footerparent {
    padding-top: 50px;
    background-color: rgb(35, 32, 31);
  }
  .footerparent footer{
    color: "white"
  }

  .footercontaint{
    margin-top: 10px;
  }
  .footercontaint p{
    margin: 10px;
    font-size: 14px;
    margin-left: 0;
    font-weight: 600;
    color: white;
  }
  .footercontaint button{
 background-color: rgb(241, 91, 41);
  color: white;
  margin-top: 10px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  padding: 12px 18px;
  }


  /* .footeritem{
    background-color: #001df707;
  } */
   .footeritem p{list-style: none;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .footeritem li{list-style: none;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }


  .custoapplogo{
    height: auto;
    width: 150px;
    border-radius: 6px;
    /* filter: invert(100%); */
    margin-bottom: 20px;
    cursor: pointer;
  }

  .payfee{ background-color: rgb(241, 91, 41);
    color: white;
    border: none;
    position: relative;
    right: 0;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
    padding: 12px 18px;

  }

  .downtoup{
    margin: 20px 0;
    border: none;
    padding: 7px;
    color: white;
    font-weight: 400;
    border-radius: 5px;
    background-color: #c7b030;
  }
  .footericons li{
    list-style: none;
    text-align: right;

  }

  .socialicons{
    display: flex;
    padding: 0;
    margin-top: 15px;
    flex-direction: row;
  }
  .socialicons p{
    color:white;
  }
  .socialicons .sicon{
    background-color: white;
    margin: 8px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    font-size: 30px;
  }
  .foterdisc{
    padding: 10px;
  }
  .foterdisc p{font-size: 18px;
  }
  @media only screen and (max-width: 991px) {
    .footersenditem{
      margin-top: 20px;
    }
  
    .footersenditem .row div ul{
    padding-left: 0px;}
  
  }
   @media only screen and (max-width: 575px) {
    .footericons li{
      text-align: left;
  
    }
  
    
   }
  
  @media (min-width:1199px) and (max-width:1399px) {
    .testo-img{
      height: 70%;
      width: 50%;}
    
      .testobox div .card{
        padding-left: calc(50% + 10px);
    
    }}
    
    @media only screen and (max-width: 1199px) {
      .testo-img{
        height: 45%;
        top: 0%; 
        left:  50%; 
        transform: translate(-50%, 0%);
        width: 100%;}
      
        .testobox div .card{
          padding-left: calc(0% + 10px);
          padding-top: calc(55% + 10px);
        
    }}
    @media only screen and (max-width: 991px) {
      .testo-img{
        height: 35%;
        top: 0%; 
        left:  50%; 
        transform: translate(-50%, 0%);
        width: 100%;}
      
        .testobox div .card{
          padding-left: calc(0% + 10px);
          padding-top: calc(65% + 10px);
        
    }}

    @media only screen and (max-width: 575px) {
      .testo-img{
        height: 45%;}
      
        .testobox div .card{
          padding-top: calc(60% + 10px);
        
    }}
    @media only screen and (max-width: 515px) {
      .testo-img{
        height: 35%;}
      
        .testobox div .card{
          padding-top: calc(75% + 10px);
        
    }}
    @media only screen and (max-width: 418px) {
      .testo-img{
        width: 80%;
        height: 30%;}
      
        .testobox div .card{
          padding-top: calc(75% + 10px);
        
    }}
    /* @media (min-width:767px) and (max-width:575px) {
      .testo-img{
        height: 55% !important;
        top: 0%; 
        opacity: 0.5;
        left:  50%; 
        transform: translate(-50%, 0%);
        width: 100%;}
      
        .testobox div .card{
          background-color: #0026ff !important;
          padding-left: calc(0% + 10px);
          padding-top: calc(65% + 10px);
      
      }} */


      /* gallery**************gallery**************gallery**************gallery**************gallery**************gallery**************gallery**************gallery**************gallery**************gallery**************gallery**************/

      .galleryboxc{
        padding: 80px 0;
      }


      .gallerysection {
        margin-top: 50px;
      }


      /* .react-photo-gallery--gallery {
        background-color: #1a2B96;
      }
      .react-photo-gallery--gallery div{
        background-color: #961a1a;
      } */
      .react-photo-gallery--gallery div img{
        object-fit: cover;
      }
      
      














  /* plasment*********** plasment******* plasment******* plasment******* plasment******* plasment******* plasment******* plasment******* plasment***********/

.placements-container{
  margin: 10px;
  padding-top: 50px;
}


#placesmgsli{
  display: none;}

@media only screen and (max-width: 767px) {
  #placebigsli{
    display: none;
  }
  #placesmgsli{
    display: block !important;
  }

}



  .plasmentbox{
    padding: 50px 0;
  }
.placesliderbox{
  /* background-color: #fd8700; */
  width: 100%;
  position: relative;
  display: flex !important;
  justify-content: center;
  
}
  .plasmcobox{
    /* background-color: aqua; */
    height: 100%;
    margin: 0 ;
  }

  .plasment-card{
    border: none;
    width: 247px;
    height: 247px;
    margin: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #e6e6e627;
    /* border-radius: 10px;
    overflow: hidden; */
    /* background-color: #fd000077; */
    /* border: 1px solid rgba(128, 128, 128, 0.11); */
  }

  .plasmentimg{
    position: relative;
    max-height: 100% !important;
    max-width: 100% !important;
    /* height: 100% ;
    width: 100% ; */
    object-fit: cover;
  }

  .newssection{
    margin-top: 20px;
    border-bottom: 1px solid rgba(248, 248, 255, 0.541);
  }

  .newsslider{
    margin: 20px 10px;
  }
  .newsslider .card{
    border: none;
    border-radius: 10px ;
    background-color: rgb(255, 255, 255) !important;
  }
  .newsslider .card div h5{
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 20px;
  }
  .newsslider .card div h6{
    font-weight: 400;
  }

  .datebox{
    background-color: rgb(241, 91, 41);
    position: absolute;
    padding: 5px 10px ;
    height: 28px;
    top: 20px;
  }

  .datebox .datedot{
    background-color: rgb(190, 69, 29);
    height: 7px;
  top: 28px;
  left: -1px;
    width: 10px;
    z-index: 13;
    clip-path: polygon(0 0, 100% 100%, 100% 0);
    position: absolute;
  }
 
  .datebox h6{
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: white;
  }

  .readmode{
    background-color: rgb(241, 91, 41);
    border: none;
    color: white;
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 4px;
    margin-top: 10px;
  }

  .readmode:hover{
    background-color: black;
  }

  /* .react-multi-carousel-list{
    overflow:visible !important;
  } */

/*   
.react-multiple-carousel__arrow--left{
  background-color: #0026ff !important;
  position: absolute !important;
  right:  0px !important;
  width: 50px;
}
.react-multiple-carousel__arrow--right{
  background-color: #0026ff !important;
}


.react-multiple-carousel__arrow{
  background-color: rgb(255, 102, 0);
  position: absolute !important;
  top: -80px;
  border-radius: 5px !important;
}
 */











 .testocard{
   margin:  30px;
 }


 .testimonial-card{
   display: block;
  min-height: 450px;
  width: 350px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0,0,0,.2);
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content:space-between;
 
}
.testimonial-card .text p:nth-child(1){
  /* background-color: #1a2B96; */
  position: relative;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  margin: 0;
}


.testimonial-card .text{
  margin: 0;
  padding: 2.2em;
  line-height: 1.7em;
  position: relative;
  font-size: 18px;
}
.image{
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 5px solid #fff ;

  /* background:no-repeat center/cover url(https://images.unsplash.com/photo-1563715992566-7ad5cf5b71d3?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ); */
  overflow: hidden;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  display:flex;
  justify-content:center;
  
}

.footert{
  /* background: linear-gradient(90deg, rgb(153, 153, 153) 0%, black); */
  background-color: #000;
  height: 180px;
  border-radius: 0 0 10px 10px;
  position:relative;
  
}




.quote{
  font-size: 400%;
  float: right;
  opacity: .1;
  transform: rotate(10deg) translate(-10px, -40px);

}

.footert h2{color:white;
  position: relative;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size:1rem;
  font-weight: 500;
  width: 100%;text-align: center;}

.joker{
  text-align: center;
  color: white;
  margin-top: 0;
  font-size: 14px;
  font-weight: lighter;
  position: relative;
  top: 5px !important;
}

.person{
  color:white;
  position: relative;
  top: 55px;
  margin-top: 20px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size:1.5rem;
  font-weight: 600;
}











  
















 #feedback {
  cursor: pointer;
position: fixed;
right: 0;
top: 50%;
z-index: 10;
transform: rotate(-90deg);
-webkit-transform: rotate(-90deg);
-moz-transform: rotate(-90deg);
-o-transform: rotate(-90deg);
filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
#feedback a{
  color: white;
  font-size: 20px;
  padding: 10px;
  position: relative;
  top: 47px;
  border-top-right-radius: 10px ;
  border-top-left-radius: 10px ;
  background-color: rgb(241, 91, 41);
  text-decoration: none;
}
#feedback a:hover{box-shadow: black 0px -3px 10px;
  text-shadow: black 0px 0px 10px;
}


  #whatsapp {
    background-color: green;
    cursor: pointer;
    height: 60px;
    width: 60px;
    padding-left: 15px;
    padding-top: 6px;
  position: fixed;
  border-radius: 50%;
  right: 20px;
  bottom: 50px;
  z-index: 10;
  }
  #whatsapp a{
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  #whatsapp a i{
    font-size: 30px;
  }

  @media only screen and (max-width: 991px) {
    #telephone {
      top: 200px !important;}
  }
  #telephone {
    cursor: pointer;
  position: fixed;
  right: 0;
  top: 41px;
  width: 212px;
  padding: 12px 0px 12px 12px;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  }
  #telephone a {
    cursor: pointer;
    position: relative;
    left: 150px;
    padding-left: 10px;
    display: block;
    font-size: 22px;
    box-shadow: black 0px 3px 10px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    color: rgb(255, 255, 255);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: rgb(255, 208, 0);
    transition: 2s ;
  }
  #telephone a:hover{
    left: 0px;
    transition: 2s ;

  }
  #telephone a i{
    font-size: 34px;
  }

 

#whatsapp a:hover{
  text-shadow: 0 0px 2px  darkgreen,
  0 0px 4px  darkgreen,
  0 0px 6px  darkgreen,
  0 0px 8px  darkgreen,
  0 0px 10px  darkgreen;
  color: yellowgreen;
}


/* @keyframes bounce {
0%, 20%, 50%, 80%, 100% {transform: translateY(0);
}
40% {transform: translateY(-30px);}
60% {transform: translateY(-15px);}
40%,60%{
      
      box-shadow: 0 0 10px 6px rgb(78, 78, 78),
      0 0 5px 3px rgb(255, 255, 255),
      0 0 4px 2px rgb(255, 255, 255),
      0 0 3px 0px rgb(255, 255, 255), 
      0 0 2px rgb(255, 255, 255);
border-radius: 10px;

border: 1px solid #0026ff;
text-shadow:  0px 0px 5px #ffffff,
0px 0px 10px #0059ff,
0px 0px 20px #0026ff,
0px 0px 40px #0026ff,
0px 0px 80px #0026ff,
0px 0px 120px #0026ff,
0px 0px 200px #0026ff;
}
80%{ box-shadow: none;
      border-radius: 0;
      border: none;
        text-shadow: none;
}

}   */
/* #feedback a {
cursor: pointer;
display: block;
background:#000;
height: 52px;
padding-top: 10px;
width: 155px;
text-align: center;
color: rgb(255, 255, 255);
font-family: Arial, sans-serif;
font-size: 17px;
font-weight: bold;
text-decoration: none;

animation-duration: 2.5s;
animation-fill-mode: both;
animation-iteration-count: infinite;
animation-name: bounce;

} */



.card-img-top{

  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}







.loader {
  height: 100vh;
  
  /* width: 150vh; */
text-align: center;
}
.loader img{
  height: 200px;
  /* width: 50%; */
  object-fit: cover;
  justify-content: center !important;
align-items: center;
}

/* coursespage**********coursespage**********coursespage**********coursespage**********coursespage**********coursespage**********coursespage**********coursespage**********coursespage**********coursespage**********/

/* .coursespage{
  background-color: #303fc748;
  margin-left:0 !important;
} */
/* .coursespagebanner{
  background-color: #c7b030;
} */


.coursespagebannerimg{
  width: 100%;
  overflow: hidden;
  margin: 0;
  max-height: 500px ;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.coursespagebannerimg img{
  height: 100%;
  width: 100%;
  /* max-height: 500px ; */
  object-fit: cover;
}
.fgsas{
  margin-top: 20px;
  margin-bottom: 30px;
}
.coursespagebannecon {
  position: relative;

}

.coursespagebannecon div{
  position: absolute;
 bottom: 60px;
 left: 150px;
 color: white;
}

@media only screen and (max-width: 767px) {
  .coursespagebannecon div{
    left: 40px !important;
 bottom: 40px;
  }

}


.coursespagebannecon div h1{
font-size: 45px;
}

.coursespage .container h1{
margin: 20px 0;
font-weight: 700;
font-size: 40px;
}

.applybtn{
  text-align: right;
}
@media only screen and (max-width: 575px) {
  .applybtn{
    text-align: left;
  }
  .conpadding{
    padding: 0 20px;
  }
}
.applybtn button{
  color: white;
  border: none;
  border-radius: 5px;
  font-family: 400px;
  background-color: rgb(223, 73, 3);
  padding: 15px 25px;
}

.coursespageimg{
  border-radius: 8px ;
  overflow: hidden;
}
.coursespageimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.ushdi{
  background-color: #22222213;
  border-radius: 8px;
}
@media only screen and (max-width: 767px) {
  .ushdi{
    margin-top: 20px;
  }
}
.coursespagetabile{
  margin-top: 0 !important;
  margin-top: 20px;
  padding: 10px 0;
}
.coursespagetabile h4{
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.coursespagetabile .row{
  margin: 10px 0;

}
@media only screen and (max-width: 575px) {
  .coursespagerelatedimg {
    max-width: 200px;
    margin: 10px 0;
  }
}
.coursespagerelatedimg{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.coursespagerelatedimg img{height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .coursespagerelatedcard{
  background-color: #53c730;
  
} */
/* .coursespagerelatedcard p:nth-child(1){
  font-weight: 500;
} */
.descrivtion{
  font:500;
  font-size: 20px;
}
.coursespagerelatedcard p:nth-child(2){
  font-weight: 400;
  
}

.coursespagecontainheader{
  margin: 10px 0;
  padding: 10px 0 0 0;
  border-bottom: 1px dashed #000000;
}
.coursespagecontainheader p{
  padding-bottom: 18px;
  font-weight: 500;
  margin: 0;
  font-size: 20px;
  width: auto;
}

/* .coursespagecontain{
  background-color: brown;
} */
.coursespagecontain h3{
  font-weight: bolder;
  margin: 15px 0;
  font-size: 35px;
}
.coursespagecontain p{
  font-weight: 500;
}


.coursespagefaqs{
  margin-top: 20px;
  margin-bottom: 50px;
}
.coursespagefaqs h5{
  font-weight: bolder;
  margin: 15px 0;
  font-size: 35px;
}
.coursespagefaqs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 20px 0;
}
.coursespagefaqs ul li{
  list-style: none;
  margin: 5px 0;
  font-weight: 600;
}
.coursespagefaqs ul li .muiicon{
  color: #fd8700;
}
.coursespagefaqs .contactu{
  font-weight: 500;
  margin-bottom: 5px;
} 


.coursespagesocial{
  display: flex;
}
.coursespagesocial p{
  margin-right: 20px;
  margin-top: 5px;
}
.coursespagesocial a{
  margin-right: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(44, 44, 44, 0.199);
}







/* Descendant of .nav, Bootstrap specificity (shouldn't be like this) */
.nav > .dropdown-megamenu {
  position: static;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-container {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      border: 0;
      box-shadow: none;
      border-radius: 0;
  }
}

.dropdown-megamenu > .dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-width: 100%;
    padding: 0.5 * 30px;
}

.dropdown-megamenu .dropdown-menu {
  display: block;
}

.link-image .media-object {
  float: left;
  margin-bottom: 0.25 * 30px;
}

.link-image-sm + .link-image-sm .media-object {
  margin-left: 0.25 * 30px;
}

.thumbnail .caption {
  min-height: 120px;
}
.thumbnail:hover {
  text-decoration: none;
}

/* Link list */
.list-links {
  list-style: none;
  padding: 0;

  
 
}
  a {
  color: #555;
  /* &:hover,
  &:focus,
  &:active {
    color:  darken(#337ab7, 15%);
  } */
}
.list-links li{
  line-height: 1.2 * 1.428571429px;
}


/* General styles to improve presentation */
html, body {
  height: 100%;
  min-height: 500px;
}

.example-megamenu {
  width: 100%;
  max-width: 1140px;
  padding: 20px;
  margin: 0 auto;
}
h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 2rem;
  color: #fff;
}
.megamenu {
  position: static;
}

.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%;
}

/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

.APP  {
  background: #eaafc8;
  background: -webkit-linear-gradient(to right, #eaafc8, #654ea3);
  background: linear-gradient(to right, #eaafc8, #654ea3);
  /* min-height: 100vh; */
}

code {
  color: #745eb1;
  background: #fff;
  padding: 0.1rem 0.2rem;
  border-radius: 0.2rem;
}

.text-uppercase {
  letter-spacing: 0.08em;
}
.megaimaga
{
  background: center center url("https://therichpost.com/wp-content/uploads/2021/11/mega.png")
  no-repeat; background-size: cover;
}